// Note: Requires flexslider 2
jQuery(document).ready(function($) {
	$('.row-checkered-slider .flexslider .slides').each(function() {
		if(this.children.length > 1) {
			$(this).parent().flexslider({
				animation: "slide",
				prevText: "",
				nextText: "",
				controlNav: false,
				directionNav: true,
			});
		}
	});
}); /* end of as page load scripts */